import Mock from "../mock";

const database = {
  information: {
    name: 'William Li',
    aboutContent: "A senior web / mobile developer 10+ years of experience in all phases of the SCRUM Software Development Life Cycle model including Requirement Analysis, UX Design, Development, Testing, Deployment, and Maintenance, provides\n\n"
      +"1) Deliver clean and high-quality code\n2) Support and maintenance with quick turnaround time\n3) Daily, weekly, monthly detailed SCRUM reports\n4) Excellent communication with fluent English\n5) 24x7 online & availability to work in a different timezone",
    age: 30,        
    skype: 'live:.cid.d1b573bf7d0bfb6c',
    language: 'English, Czech, Chinese',
    email: 'williamli0608@gmail.com',        
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/weilinli68/',
      dribbble: '',
      github: 'https://github.com/topdeveloper104'
    },
    brandImage: '/images/williamli.png',
    aboutImage: '/images/williamli.jpg',
    aboutImageLg: '/images/williamli.jpg',
    cvfile: '/files/williamli.pdf'
  },
  services: [    
    {
      title: "Web Development",
      icon: 'code',
      details: "Website Fullstack Development\nWeb App Development\nWeb Portal Development"
    },
    {
      title: "Mobile App Development",
      icon: 'mobile',
      details: "iOS App Development\nAndroid App Development\nCross-Platform Development"
    }
  ],
  reviews: [
    {
      id: 1,
      content: "What a relif! I finally found a real dedicated and professional developer. My project was too complicated to be completed, so I was about to give up my project. Just a last shot, I gave my project to William and he made it happen! I really appreciate the quality work he has done. Definitely highly recommend him!",
      author: {
        name: 'Mario Molina',
        designation: 'CEO & Founder of Webllywood'
      }
    },
    {
      id: 2,
      content: "This was a fantastic - first experience. I was trying to figure out a good way of getting this service done. I am a residential developer and didn’t have much time to work on this. William kicked in and knocked it out the park. We had a few chats that gave him direction and he produced. I will be back to work with William.",
      author: {
        name: 'Ash R.',
        designation: 'Freelancer Client'
      }
    },
    {
      id: 3,
      content: "I'm giving high five to William Li, because I'm very satisfied with the result. It was not an easy job I believe, But William managed very well and Well Done. Highly recommended!",
      author: {
        name: 'Kareem Waterman',
        designation: 'Founder of Luna'
      }
    },
    {
      id: 4,
      content: "Very happy with the quality of work and his ability to take what I needed and turn it into a case study that matched what I was hoping!",
      author: {
        name: 'Justin Adelman',
        designation: 'Freelancer Client'
      }
    },
    {
      id: 5,
      content: "Great to work with William! He is one of the most competent developers who I ever met so far. I definitely hire him again for my future projects! Thanks William, for your awesome work!",
      author: {
        name: 'Roman Steele',
        designation: 'Upwork Client'
      }
    },
    {
      id: 6,
      content: "Excellent programmer! One of, if not, the best I've found in 5 years on my career. I highly recommend using William Li for any project. He exceeded my expectation. Will be working with him again in the future.",
      author: {
        name: 'Tara Sims',
        designation: 'Remote Employer'
      }
    },
    {
      id: 7,
      content: "As always, work is great. Thank William for always providing me great work.",
      author: {
        name: 'Jayson Williamson',
        designation: 'Remote Employer'
      }
    },
    {
      id: 8,
      content: "很专业。非常乐于协助和进行更改。易于沟通。强烈推荐。",
      author: {
        name: '崔天',
        designation: 'Local Employer'
      }
    },
    {
      id: 9,
      content: "William went above and beyond on my project and I cannot recommend him enough. We worked together to design something that was out of scope of my original investment but William took the time to research how to get what we needed done and apply it masterfully. I have hired him again and will continue to work with him on many future projects. Thank you!",
      author: {
        name: 'Max S.',
        designation: 'Freelancer Client'
      }
    },
    {
      id: 10,
      content: "It was a real pleasure working with William. He understood the task and delivered everything up to the last detail, days before the deadline. He is very patient and professional. No doubt that I would work with him again.",
      author: {
        name: 'Vladimir S.',
        designation: 'Upwork Client'
      }
    },
  ],
  skills: [
    {
      title: "Kotlin",
      value: 100
    },
    {
      title: "Flutter",
      value: 90
    },
    {
      title: "Swift",
      value: 100
    },
    {
      title: "React",
      value: 100
    },
    {
      title: "Angular.js",
      value: 100
    },
    {
      title: "Vue.js",
      value: 100
    },
    {
      title: "Python",
      value: 100
    },
    {
      title: "Ruby",
      value: 100
    },
    {
      title: "Node.js",
      value: 100
    },
    {
      title: "PHP",
      value: 100
    },
    {
      title: "WordPress",
      value: 100
    },
    {
      title: "eCommerce",
      value: 100
    },
    {
      title: "CMS",
      value: 100
    },
    {
      title: "Ionic",
      value: 100
    },
    {
      title: "Xamarin",
      value: 100
    },
    {
      title: "Scala",
      value: 90
    },
    {
      title: "Go",
      value: 100
    },
    {
      title: "Erlang",
      value: 90
    },
    {
      title: ".NET",
      value: 100
    },
    {
      title: "Unity",
      value: 90
    },
    {
      title: "SCRUM",
      value: 100
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Gramercy Park Hotel",
      subtitle: "The official site of the Gramercy Park Hotel.",
      imageUrl: "/images/gramercy_small.png",
      largeImageUrl: [
        "/images/gramercy1.png",
        "/images/gramercy2.png",
        "/images/gramercy3.png",        
      ],
      url: 'https://www.gramercyparkhotel.com/'
    },        
    {
      id: 2,
      title: "Vivino",
      subtitle: "With 42 million users, Vivino is the world’s largest wine community and the ultimate destination for discovering and buying wines.",
      imageUrl: "/images/vivino_small.png",
      largeImageUrl: [
        "/images/vivino1.png",
        "/images/vivino2.png",
        "/images/vivino3.png"        
      ],
      url: 'https://www.vivino.com/',
      ios: 'https://apps.apple.com/app/id414461255',
      android: 'https://play.google.com/store/apps/details?id=vivino.web.app'
    },     
    {
      id: 3,
      title: "Stake",
      subtitle: "Modern Bitcoin Casino & Sports betting platform. Instant deposits & withdrawals. Provably fair slots & games.",
      imageUrl: "/images/stake_small.png",
      largeImageUrl: [
        "/images/stake1.png",
        "/images/stake2.png",
        "/images/stake3.png",        
      ],
      url: 'https://stake.com/'      
    },
    {
      id: 4,
      title: "Hop Skip Drive",
      subtitle: "HopSkipDrive is the most caring, tech-enabled ride service for kids ages 6+.",
      imageUrl: "/images/drive_small.png",
      largeImageUrl: [
        "/images/drive1.png",
        "/images/drive2.png",
        "/images/drive3.png",        
      ],
      url: 'https://www.hopskipdrive.com/',
      ios: 'https://apps.apple.com/app/hopskipdrive-rideshares-carpools-for-kids/id950823144',
      android: 'https://play.google.com/store/apps/details?id=com.hopskipdrive.hsd_android_v2'
    },        
    {
      id: 5,
      title: "Towel & Robe",
      subtitle: "eCommerce website of Turkish robes and towels coming factory direct.",
      imageUrl: "/images/towel_small.png",
      largeImageUrl: [
        "/images/towel1.png",
        "/images/towel2.png",
        "/images/towel3.png",        
      ],
      url: 'https://www.towelnrobe.com/'      
    },  
    {
      id: 6,
      title: "Trucking Jobs & Services",
      subtitle: "First mobile app in the trucking industry designed to find trucking jobs with just one click.",
      imageUrl: "/images/trucking_small.png",
      largeImageUrl: [
        "/images/trucking1.png",
        "/images/trucking2.png",
        "/images/trucking3.png",        
      ],
      url: 'https://driverschoice.ca/',
      ios: 'https://apps.apple.com/us/app/trucking-jobs-services/id1204864105',
      android: 'https://play.google.com/store/apps/details?id=com.netmente.truckingapp'
    },    
    {
      id: 7,
      title: "Loops Live",
      subtitle: "Loops live is a tool designed for Arabs to connect, communicate and interact with each other in 100% Arab way. You can always find the community content that you are interested in, engage with like-minded people like yourself, or even feel loved and respected.",
      imageUrl: "/images/loopslive_small.png",
      largeImageUrl: [
        "/images/loopslive1.png",
        "/images/loopslive2.png"        
      ],
      url: 'https://www.loopslive.com/#/live/index',
      ios: 'https://apps.apple.com/is/app/loops/id1085411495',
      android: 'https://play.google.com/store/apps/details?id=mozat.rings.loops'
    },        
    {
      id: 8,
      title: "Bring Fido",
      subtitle: "BringFido is the world's leading pet travel and lifestyle brand. It connects millions of pet owners around the globe with more than 250,000 pet friendly places.",
      imageUrl: "/images/BringFido_small.png",
      largeImageUrl: [
        "/images/BringFido.png",
        "/images/BringFido1.png",
        "/images/BringFido2.png",
        "/images/BringFido3.png",
        "/images/BringFidoMobile1.png"
      ],
      url: 'https://www.bringfido.com/',
      ios: 'https://apps.apple.com/app/bringfido-pet-friendly-hotels/id682820712',
      android: 'https://play.google.com/store/apps/details?id=com.bringfido.bringfido'
    },        
    {
      id: 9,
      title: "Kiwi",
      subtitle: "Discover a new way of travelling with the interactive map, airport, and destination guides in your pocket.",
      imageUrl: "/images/kiwi_small.png",
      largeImageUrl: [
        "/images/kiwi1.png",
        "/images/kiwi2.png",
        "/images/kiwi3.png"
      ],
      url: 'https://www.kiwi.com/'  
    }, 
    {
      id: 10,
      title: "Dahmakan",
      subtitle: "Dahmakan is an in-house food delivery service. It delivers always excellent food at your convenience, in Malaysia and Thailand.",
      imageUrl: "/images/dahmakan_small.png",
      largeImageUrl: [
        "/images/dahmakan1.png",
        "/images/dahmakan2.png"        
      ],
      url: 'https://dahmakan.com/kuala-lumpur/menu',
      ios: 'https://apps.apple.com/app/dahmakan/id1030383844',
      android: 'https://play.google.com/store/apps/details?id=com.app.makano'
    },
    {
      id: 11,
      title: "Online Courses",
      subtitle: "OCA offers the ultimate short course learning experience in Australia. Choose from dozens of courses.",
      imageUrl: "/images/course_small.png",
      largeImageUrl: [
        "/images/course1.png",
        "/images/course2.png"                
      ],      
      url: 'https://www.onlinecoursesaustralia.edu.au/'      
    },
    {
      id: 12,
      title: "DoorDash",
      subtitle: "DoorDash offers the greatest selection of your favorite local and national restaurants, more than 310,000 menus across 4,000+ cities in the U.S., Canada and Australia.",
      imageUrl: "/images/doordash_small.png",
      largeImageUrl: [
        "/images/doordash.png"        
      ],      
      ios: 'https://apps.apple.com/app/doordash-food-delivery/id719972451',
      android: 'https://play.google.com/store/apps/details?id=com.dd.doordash'
    },
    {
      id: 13,
      title: "Appy Couple",
      subtitle: "A stylish, multi-tasking wedding website and app for you and your guests. Share events, manage RSVPs, collect photos and more.",
      imageUrl: "/images/couple_small.png",
      largeImageUrl: [
        "/images/couple1.png",
        "/images/couple2.png",
        "/images/couple3.png"                
      ],
      url: 'https://www.appycouple.com/',
      ios: 'https://apps.apple.com/app/appy-couple/id492345619',
      android: 'https://play.google.com/store/apps/details?id=com.appycouple.android'
    },
    {
      id: 14,
      title: "Journi",
      subtitle: "The biggest Journi photo book ever for your favorite memories and a glossy finish to give them a premium touch.",
      imageUrl: "/images/JourniApp_small.png",
      largeImageUrl: [
        "/images/JourniApp.png",
        "/images/JourniApp1.png",
        "/images/JourniApp2.png",
        "/images/JourniApp3.png",
        "/images/JourniApp4.png",
        "/images/JourniApp5.png",
        "/images/JourniMobile.png",
        "/images/JourniMobile1.png",
      ],
      url: 'https://www.journiapp.com/',
      ios: 'https://apps.apple.com/app/id1359161090',
      android: 'https://play.google.com/store/apps/details?id=com.journiapp.book'
    },    
    {
      id: 15,
      title: "MeetMe: Chat & Meet New People",
      subtitle: "MeetMe helps you find new people nearby who share your interests and want to chat now. It’s fun, friendly, and free.",
      imageUrl: "/images/meet_small.png",
      largeImageUrl: [
        "/images/meet1.png",
        "/images/meet2.png"        
      ],
      url: 'https://www.meetme.com/',
      ios: 'https://apps.apple.com/app/meetme-chat-meet-new-people/id372648912',
      android: 'https://play.google.com/store/apps/details?id=com.myyearbook.m'
    },
    {
      id: 16,
      title: "Photo Video Maker with Music",
      subtitle: "It lets you create, edit and share amazing music videos, slideshows and stories with your photos, videos and free licensed music. Add filters, text, music and create personalized videos from your pictures.",
      imageUrl: "/images/video_small.png",
      largeImageUrl: [
        "/images/video.png"        
      ],      
      android: 'https://play.google.com/store/apps/details?id=videomedia.photovideomaker'
    },
    {
      id: 17,
      title: "At Home Plank Workouts",
      subtitle: "The app provides different plank variations to help you lose weight, gain strength and get a stronger core.",
      imageUrl: "/images/health_small.png",
      largeImageUrl: [
        "/images/health.png"        
      ],      
      ios: 'https://apps.apple.com/app/at-home-plank-workouts/id1442191346',
      android: 'https://play.google.com/store/apps/details?id=fat.burnning.plank.fitness.loseweight'
    },
    {
      id: 18,
      title: "Zchol",
      subtitle: "School Booking System. Zchol visualize your school.",
      imageUrl: "/images/zchol_small.png",
      largeImageUrl: [
        "/images/zchol1.png",
        "/images/zchol2.png"                
      ],
      url: 'https://www.zchol.com/en/home/',
      ios: 'https://apps.apple.com/app/id1474612393',
      android: 'https://play.google.com/store/apps/details?id=com.zchol'
    },
    {
      id: 19,
      title: "Video Poker",
      subtitle: "Video Poker Free - Double Bonus - Double Up",
      imageUrl: "/images/VideoPoker_small.png",
      largeImageUrl: [
        "/images/VideoPoker.png",
      ],
      ios: 'https://itunes.apple.com/app/poker-lock/id1218192823',
      android: 'https://play.google.com/store/apps/details?id=com.teraception.poker.lock'
    },
    {
      id: 20,
      title: "Expense IQ Money Manager",
      subtitle: "An ultimate money manager app that combines an expense tracker, a budget planner, a checkbook register, integrated bills reminder and more rolled into one powerful personal finance app.",
      imageUrl: "/images/budget_small.png",
      largeImageUrl: [
        "/images/budget.png",
      ],      
      android: 'https://play.google.com/store/apps/details?id=com.handyapps.expenseiq'
    },
    {
      id: 21,
      title: "Fast Crash Races",
      subtitle: "A game full of dangerous races, hundreds of obstacles to avoid and streets full of traps.",
      imageUrl: "/images/Race_small.png",
      largeImageUrl: [
        "/images/Race.png",
      ],
      ios: 'https://itunes.apple.com/app/fast-crash-races/id1317515183',
      android: 'https://play.google.com/store/apps/details?id=com.ben.fastcrash'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2014 - Present",
        position: "Professional Freelancer",
        company: "Self-Employed",
        details: "• Managed to complete more than 40 web, mobile and desktop applications for clients in Europe, UK, and the USA successfully.\n• Designed and developed analysis systems to extract information from large scale data."
      },
      {
        id: 2,
        year: "2012 - 2014",
        position: "Mobile Application Developer",
        company: "Cambridge Apps LTD",
        details: "• Designed and developed 2 food delivery apps that were downloaded over 100K times within the first two months after publishing.\n• Improved product quality through code reviews, writing effective unit tests and collaborating with QA on implementing automation testing."
      },
      {
        id: 3,
        year: "2008 - 2011",
        position: "Full-Stack Web Developer",
        company: "Nuabi s.r.o",
        details: "• Designed a customer web form for a local beauty shop. Increased customer satisfaction scores by 50%. \n• Prototyped an average of 7 new product features per year."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2012 - 2014",
        graduation: "Master's degree",
        university: "Massachusetts Institute of Technology (MIT)",
        details: ""
      },
      {
        id: 2,
        year: "2007 - 2011",
        graduation: "Bachelor's degree",
        university: "Charles University in Prague",
        details: ""
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: "React new version is coming!",
      imageUrl: "/images/blog-image-1.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 2,
      title: "More about react hooks",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "04 December 2019"
    },
    {
      id: 3,
      title: "Next generation javascript learning source",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "05 December 2019"
    },
    {
      id: 4,
      title: "Free tutorial downloading link.",
      imageUrl: "/images/blog-image-2.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "06 December 2019"
    },
    {
      id: 5,
      title: "Get 2500 free mockup for design.",
      youtubeUrl: "TKnufs85hXk",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "08 December 2019"
    },
    {
      id: 6,
      title: "React vs Vue vs Angular, what is best?",
      vimeoUrl: "23534361",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "10 December 2019"
    },
    {
      id: 7,
      title: "Web design typography & spacing.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 8,
      title: "React new version is coming with a great features.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    }
  ],
  contactInfo: {
    emailAddress: ['williamli0608@gmail.com'],
    skype: "live:.cid.d1b573bf7d0bfb6c"
  }
}

Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});